$ = jQuery;

export function hamburger() {
    
    const header = $('.header');
    const hamburger = $('.menu-hamburger span'); 
       
    $('.menu-hamburger').click(function(){
        $(this).toggleClass('open');
        header.fadeToggle();
        header.css('display','flex');
        $('body').toggleClass('body-overflow');
        if (scrollY >= 50) {
            hamburger.toggleClass('color');
        }
    });

    let mobile = window.matchMedia('(min-width: 0px) and (max-width: 1200px)');

    if (mobile.matches) {
        $('.header__menu-link').click(function(){
            header.fadeToggle();
            $('body').toggleClass('body-overflow');
            $('.menu-hamburger').toggleClass('open');
        });
        $('.header__button').click(function(){
            header.fadeToggle();
            $('body').toggleClass('body-overflow');
            $('.menu-hamburger').toggleClass('open');
        });
    }

    $(window).scroll(function() {
        const scrollY = $(window).scrollTop();

        if (scrollY >= 50) {
            hamburger.addClass('color');
            header.addClass('header--active');

        } else {
            hamburger.removeClass('color');
            header.removeClass('header--active');

        }
    });

}
