$ = jQuery;

export function scroll() {

    const link = $('.js-scroll-section');
    
    link.on('click', function(e) {
        e.preventDefault();

        let target = $($(this).attr('href'));
        if (target.length) {
            $('html, body').animate({
                scrollTop: target.offset().top - 60
            }, 1000);
        }
    });

}
