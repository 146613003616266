import Aos from 'aos';
import { hamburger } from './modules/header.js';
import { scroll} from './modules/scroll-section.js';

var documentReady = function () {
    console.log("DOM Loaded");

    // Zmienna globalna przenosząca tłumaczenia. Konfigurowalna w config.php.
    console.log(i18n);

    // RxJS working
    // fromEvent(document, "click").subscribe(() => {
    //     test();
    // });
    hamburger();
    scroll();

    Aos.init({
        disable: 'mobile',
        anchorPlacement: 'top-bottom',
        once: true,
    });
};

if (
    document.readyState === "complete" ||
    (document.readyState !== "loading" && !document.documentElement.doScroll)
) {
    documentReady();
} else {
    document.addEventListener("DOMContentLoaded", documentReady);
}
